<template>
	<div>
		<div class="b-head-block">
			<div class="b-container">
				<div class="b-head-block__wrapper">
					<h1 class="b-title b-title--h1">Назначение уполномоченных</h1>
				</div>
			</div>
		</div>
		<div class="b-container">
			<div class="b-wrapper-page">
				<div :class="{ 'b-filter': true, open: open }">
					<button @click="open = !open" class="b-filter__button">
						<iconSvg v-if="!open" class="b-icon b-icon--filter icon-filter icon" />
						<span v-if="!open">Фильтр записей</span>

						<iconCloseSvg v-if="open" class="b-icon b-icon--close icon-close icon" />
						<span v-if="open">Скрыть</span>
					</button>
					<form class="b-filter__hidden" onsubmit="return false">
						<div class="b-filter__form">
							<div class="b-input b-input--dual b-input--dual b-input--clinical b-input--search input-group">
								<template v-for="(item, key) in filter">
									<div v-if="item.type === 'text'" :key="key" class="b-input b-input--dual b-input--dual b-input--clinical b-input--search">
										<input
											:id="`search-filter${item.id}`"
											:placeholder="item.name"
											v-model="item.value"
											class="b-input__input-field b-input__input-field--dual b-input__input-field--dual b-input__input-field--clinical b-input__input-field--search"
											type="search"
											name="search-filter-name"
										/>
										<iconSearchSvg class="b-icon b-icon--table-search" />
										<label class="b-input__label" for="search-filter">{{ item.name }}</label>
									</div>
									<div v-else-if="item.type === 'select'" class="b-select b-select--dual b-select--clinical b-select--type">
										<v-select
											:placeholder="item.name"
											multiple
											v-model="item.value"
											:reduce="obj => obj.id"
											:options="item.list"
											:loading="item.list.length === 0"
											label="name"
										>
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">Поиск не дал результатов <em>{{ search }}</em>.</template>
											</template>
										</v-select>
									</div>
								</template>
                                <div class="v-item-group v-slide-group v-chip-group ml-3">
									<div class="v-slide-group__wrapper">
										<div class="v-slide-group__content">
											<label aria-hidden="true" class="pt-1 pr-3 v-label" style="left: 0px; right: auto; position: relative;">
												Уполномочен подписывать документы:
											</label>
											<span
                                                @click="selected('Да')"
                                                :class="{ 'primary v-chip--active': arraySign.indexOf('Да') > -1 }"
                                                class="v-chip v-chip--clickable v-chip--no-color v-size--default theme--light"
                                            >
												<span class="v-chip__content">Да</span>
											</span>
											<span
                                                @click="selected('Нет')"
                                                :class="{ 'primary v-chip--active': arraySign.indexOf('Нет') > -1 }"
                                                class="v-chip v-chip--clickable v-chip--no-color v-size--default theme--light"
                                            >
												<span class="v-chip__content">Нет</span>
											</span>
										</div>
									</div>
								</div>
							</div>

							<button @click="searchFunc(filter, arraySign)" class="b-button b-button--fill">
								<span>Поиск</span>
							</button>
							<button @click="reset(filter, arraySign)" class="ml-2 b-button b-button--cancel">
								<span>Сбросить фильтр</span>
							</button>
						</div>
					</form>
				</div>

                <div class="mt-7">
                    <button @click="$router.push('/assignment/user/new')" class="b-button b-button--fill">
                        <span>+ Добавить пользователя</span>
                    </button>
                </div>

				<div class="b-table-list b-table-list--summary">
					<div class="b-table-list__top">
						<div class="b-table-list__left">
							<div class="b-table-list__all">
								Всего записей:<span>{{ allStroke }}</span>
							</div>
						</div>
					</div>
					<div class="b-table-list__table">
						<div style="position: relative; min-height: 300px" class="b-table-list__scroll b-table-list__scroll--table js-horizontal-scroll">
							<div v-if="isLoading" class="ring-load">
								<RingLoader class="ring-load-loading" color="#04092a" :size="160" />
							</div>
							<table>
								<thead>
									<tr>
										<th onselectstart="return false" onmousedown="return false" v-for="(item, key) in columns" :key="key" :style="`width: ${item.width}`">
											<span @click="sortFunc(item)" style="cursor: pointer">{{ item.name }}</span>

											<div v-show="checkColumn == item.nameEng" class="b-table-list__arrow">
												<button v-if="item.sort" class="b-table-list__top-button">
													<iconSortupSvg class="b-icon b-icon--table-button icon-sort-up" />
												</button>
												<button v-else class="b-table-list__bottom-button">
													<iconSortdownSvg class="b-icon b-icon--table-button icon-sort-up" />
												</button>
											</div>
										</th>
                                        <th>Действия</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row, key) in list" :key="key">
										<td v-if="authUser.role.id === 10" class="plain-text">
											{{ row.roleName }}
										</td>
										<td class="plain-text">
											{{ row.name }}
										</td>
										<td class="plain-text">
											{{ row.login }}
										</td>
                                        <td>
											<div
                                                @click.prevent="enableSign(row)"
												class="v-input d-inline-flex v-input--hide-details v-input--dense v-input--selection-controls v-input--switch v-input--switch--inset mt-0 pt-0"
												:class="{
													'v-input--is-label-active v-input--is-dirty primary--text': row.signEnabled,
													'v-input--is-disabled': authUser.id === row.id
												}"
                                            >
												<div class="v-input__control">
													<div class="v-input__slot">
														<div class="v-input--selection-controls__input">
															<input
																:value="row.signEnabled"
																:aria-checked="row.signEnabled"
																:id="`input-308-${key}`"
																role="switch"
																type="checkbox"
																:aria-disabled="authUser.id === row.id"
																:disabled="authUser.id === row.id ? 'disabled' : ''"
															>
															<div class="v-input--selection-controls__ripple" :class="{ 'primary--text': row.signEnabled }" />
															<div class="v-input--switch__track" :class="{ 'primary--text': row.signEnabled }"></div>
															<div class="v-input--switch__thumb" :class="{ 'primary--text': row.signEnabled }"></div>
														</div>

														<label
															:for="`input-308-${key}`"
															class="v-label"
															:class="{ 'v-label--is-disabled': authUser.id === row.id }"
															style="left: 0px; right: auto; position: relative;">
															<div>{{ `${row.signEnabled ? 'Да' : 'Нет'}` }}</div>
														</label>
													</div>
												</div>
											</div>
										</td>
                                        <td class="plain-text">
											<button
                                                v-if="row.signEnabled && row.signatureFileId"
                                                type="button"
                                                class="btn-link v-btn v-btn--plain v-btn--text theme--light v-size--default primary--text"
                                                @click="downloadECP(row)"
                                            >
												<span class="v-btn__content">Открытая часть ЭЦП</span>
											</button>
											<span v-else-if="row.signEnabled && !row.signatureFileId" class="hint">Загрузите ЭЦП</span>
											<span v-else>&mdash;</span>
										</td>
                                        <td>
                                            <a title="Загрузить ЭЦП">
                                                <iconDownloadSvg
                                                    v-if="row.signEnabled"
                                                    class="b-icon icon-upload pointer mr-2"
                                                    @click="openFileDownloadDialog(row)"
                                                />
                                            </a>

                                            <a title="Редактировать" @click="$router.push(`/assignment/user/${row.id}`)">
                                                <iconEditSvg class="b-icon icon-edit pointer mr-2" :class="{ 'ml-5': !row.signEnabled }" />
                                            </a>

                                            <a :title="row.isBlocked ? 'Разблокировать' : 'Заблокировать'" @click.prevent="disabledUser(row)">
                                                <iconUnlockSvg v-if="row.isBlocked" class="b-icon fsize-icon pointer" />
                                                <iconLockSvg v-else class="b-icon icon-clean pointer" />
                                            </a>
										</td>
									</tr>
								</tbody>
							</table>

							<center v-if="list.length == 0 && !isLoading" style="text-align: center">
								<p>Данных нет...</p>
							</center>
						</div>
					</div>
				</div>
				<div v-if="!isLoading" class="b-wrapper-page__bottom">
					<div class="b-pagination">
						<ul class="b-pagination__list">
							<li class="b-pagination__item-prev">
								<a
									@click="prevPage()"
									:class="{
										'b-button': true,
										'b-button--slider': true,
										'b-button--disabled': pageNumber == 0
									}"
									href="javascript:void(0);"
									title=""
								>
									<iconAngleleftSvg class="b-icon icon-angle-left" />
								</a>
							</li>

							<li class="b-pagination__item">
								<span>{{ resultCount > 0 ? pageNumber + 1 : 0 }} из {{ resultCount }} стр</span>
							</li>

							<li class="b-pagination__item-next">
								<a
									@click="nextPage()"
									:class="{
										'b-button': true,
										'b-button--slider': true,
										'b-button--disabled': pageNumber >= resultCount - 1
									}"
									href="javascript:void(0);"
									title=""
								>
									<iconAnglerightSvg class="b-icon icon-angle-left" />
								</a>
							</li>
						</ul>
					</div>
					<div class="b-number-page">
						<div class="b-number-page__text">
							Показать кол-во строк
							<span></span>
						</div>
						<div class="b-number-page__text">
							<select @change="updateListEvent(params.offSetFetch.fetchRowCount)" class="custom-select" v-model="params.offSetFetch.fetchRowCount">
								<option :value="5">5</option>
								<option :value="10">10</option>
								<option :value="20">20</option>
								<option :value="30">30</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>

        <input @change="uploadFile($event, currentRow)" hidden ref="fileAssign" type="file" accept=".cer">
	</div>
</template>

<script>
import iconSvg from '../../assets/icons/svg/filter.svg';
import iconCloseSvg from '../../assets/icons/svg/close.svg';
import iconSearchSvg from '../../assets/icons/svg/search.svg';
import iconAngleDownSvg from '../../assets/icons/svg/angle-down.svg';
import iconAngleleftSvg from '../../assets/icons/svg/angle-left.svg';
import iconAnglerightSvg from '../../assets/icons/svg/angle-right.svg';
import iconDownloadSvg from '../../assets/icons/svg/download.svg';
import iconSortdownSvg from '../../assets/icons/svg/sort-down.svg';
import iconSortupSvg from '../../assets/icons/svg/sort-up.svg';
import iconEditSvg from '../../assets/icons/svg/edit.svg';
import iconLockSvg from '../../assets/icons/svg/lock.svg';
import iconUnlockSvg from '../../assets/icons/svg/unlock.svg';
import { RingLoader } from '@saeris/vue-spinners';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'listCr',
	components: {
		RingLoader,
		iconEditSvg,
		iconLockSvg,
		iconUnlockSvg,
		iconSvg,
		iconSearchSvg,
		iconAngleDownSvg,
		iconCloseSvg,
		iconDownloadSvg,
		iconSortdownSvg,
		iconSortupSvg,
		iconAngleleftSvg,
		iconAnglerightSvg
	},
    computed: {
        ...mapGetters({
			apiUrl: 'apiUrl'
        }),
        authUser() {
            let user = JSON.parse(localStorage.getItem('userInfo'));

            return user.userInfo;
        }
    },
	data: () => ({
        edite: false,
        arraySign: ['Да', 'Нет'],
        file: null,
        currentRow: null,
		dialogAddUser: false,
		searchBtnFunc: false,
		isSearch: false,
		isLoading: false,
		allStroke: 0,
		open: false,
		checkColumn: '',
        objNew: {
            email: '',
            name: '',
            isBlocked: false,
            signEnabled: false
        },
		filter: [
			{ name: 'ФИО', value: null, id: 'fio', type: 'text' },
			{ name: 'Логин', value: null, id: 'login', type: 'text' }
		],
		columns: [
			{
				name: 'ФИО',
				nameEng: 'name',
				width: '22%',
				sort: false,
				click: false
			},
			{
				name: 'Логин',
				nameEng: 'login',
				width: '22%',
				sort: false,
				click: false
			},
			{
				name: 'Уполномочен подписывать документы',
				nameEng: 'authorized',
				width: '24%',
				sort: false,
				click: false
			},
			{
				name: 'Открытая часть ЭЦП',
				nameEng: 'eds',
				width: '22%',
				sort: false,
				click: false
			}
		],
		list: [],
		pageNumber: 0,
		resultCount: 0,
		search: '',
		searchResult: [],
        params: {
            offSetFetch: {
                offsetRowCount: 0,
                fetchRowCount: 5,
                use: true
            },
            orderByColumn: {
                columnName: "name",
                orderOperation: "ASC"
            }
        }
	}),
	methods: {
        ...mapActions({
			apiAll: 'api/apiAll'
        }),
		sortFunc(e) {
			this.checkColumn = e.nameEng;
			e.sort = !e.sort;
			e.click = true;

            this.params.orderByColumn.columnName = e.nameEng
            this.params.orderByColumn.orderOperation = e.sort ? 'ASC' : 'DESC'

            this.get(this.params);
		},
		nextPage() {
            this.pageNumber += 1;
			this.params.offSetFetch.offsetRowCount = this.pageNumber * this.params.offSetFetch.fetchRowCount;
            this.get(this.params);
		},
		prevPage() {
            this.pageNumber -= 1;
			this.params.offSetFetch.offsetRowCount = this.pageNumber * this.params.offSetFetch.fetchRowCount;
            this.get(this.params);
		},
		searchFunc(filter, arraySign) {
            console.log('searchFunc', filter, arraySign);

            let roles = undefined,
				login = undefined,
                fio = undefined,
                signEnabled = undefined;

            for (let i = 0; i < filter.length; i++) {
                if (filter[i].value) {
                    if (filter[i].id === 'roles' && filter[i].value.length > 0) roles = filter[i].value;
                    if (filter[i].id === 'login') login = filter[i].value;
                    if (filter[i].id === 'fio') fio = filter[i].value;
                }
            }

            if(arraySign.length > 0) {
                // если выбрано только Да
                if(arraySign.indexOf('Да') > -1 && arraySign.indexOf('Нет') === -1) {
                    signEnabled = true;
                }

                // если выбрано только Нет
                if(arraySign.indexOf('Нет') > -1 && arraySign.indexOf('Да') === -1) {
                    signEnabled = false;
                }

                // если выбрано оба варианта
                if(arraySign.indexOf('Нет') > -1 && arraySign.indexOf('Да') > -1) {
                    signEnabled = undefined;
                }
            }

			if (roles) this.params.roleIds = roles;
            if (!roles) delete this.params.roleIds;

            if(login) this.params.email = login;
            if(!login) delete this.params.email;

            if(fio) this.params.name = fio;
            if(!fio) delete this.params.name;

            if (signEnabled !== undefined) this.params.signEnabled = signEnabled;
            else delete this.params.signEnabled;

            this.get(this.params);
		},
		reset() {
            this.filter = [
                { name: 'ФИО', value: null, id: 'fio' },
                { name: 'Логин', value: null, id: 'login' }
            ];
            this.arraySign = ['Да', 'Нет'];

            this.searchFunc(this.filter, this.arraySign);
		},
		updateListEvent() {
            this.pageNumber = 0;
            this.params.offSetFetch.offsetRowCount = this.pageNumber * this.params.offSetFetch.fetchRowCount;
			this.get(this.params);
		},
        selected(value) {
			console.log(value);
            if(this.arraySign.indexOf(value) > -1) {
                let index = this.arraySign.findIndex((f) => f === value);
                this.arraySign.splice(index, 1);
					 console.log(this.arraySign);
                return;
            }

            this.arraySign.push(value);
        },
        openFileDownloadDialog(row) {
            this.currentRow = row;
            this.$refs.fileAssign.click()
        },
        uploadFile(event, row) {
            let file = event.target.files[0];

            if(!file) {
                this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Произошла ошибка при загрузке файла, обратитесь к Администратору!' })
                return;
            }

            let fData = new FormData();

            fData.append('file', file);

            this.apiAll({ type: 'post', url: `api/SignUser/${row.id}/AddSignature`, obj: fData })
                .then((res) => {
                    if(res.data.success) {
						row.signatureFileId = res.data.result;
                        this.$notify({ group: 'foo', duration: 5000, type: 'success', title: 'Успех', text: 'Сертификат успешно загружен!' })
                        return;
                    }

                    this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Произошла ошибка при загрузке сертификата, обратитесь к Администратору!' })
                })
                .catch((error) => {
                    if(error) {
                        row.signEnabled = !row.signEnabled;
                        this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Произошла ошибка при загрузке сертификата, обратитесь к Администратору!' })
                    }
                })


            // console.log(file, row);
        },
        enableSign(row) {
			if (this.authUser.id === row.id) {
				return;
			}

            this.apiAll({type: 'put', url: `api/SignUser/${row.id}/EnableSign`})
                .then((res) => {
                    if(res.data.success) {
                        row.signEnabled = !row.signEnabled;
						if (!row.signEnabled) row.signatureFileId = null;

                        this.$notify({ group: 'foo', duration: 5000, type: 'success', title: 'Успех', text: `Пользователь ${row.name}, ${row.signEnabled ? '' : 'не '} уполномочен подписывать документы!` })
                        return;
                    }

                    this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Произошла ошибка при обновлении данных, обратитесь к Администратору!' })
                })
                .catch((error) => {
                    if(error) {
                        row.signEnabled = !row.signEnabled;
                        this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Произошла ошибка при обновлении данных, обратитесь к Администратору!' })
                    }
                })
        },
        disabledUser(row) {
            let isBlocked = !row.isBlocked;
            this.apiAll({type: 'put', url: `api/Users/${row.id}/blocking?isBlocked=${isBlocked}`})
                .then((res) => {
                    if(res.status === 200) {
                        row.isBlocked = !row.isBlocked;
                        this.$notify({ group: 'foo', duration: 5000, type: 'success', title: 'Успех', text: `Пользователь ${row.name}, ${row.isBlocked ? 'заблокирован' : 'разблокирован '}!` })
                        return;
                    }

                    this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Произошла ошибка при обновлении данных, обратитесь к Администратору!' })
                })
                .catch((error) => {
                    if(error) {
                        row.isBlocked = !row.isBlocked;
                        this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Произошла ошибка при обновлении данных, обратитесь к Администратору!' })
                    }
                })
        },
        downloadECP(row) {
            this.api.get(`${this.apiUrl}api/SignUser/${row.id}/DownloadSignature`, { responseType: 'blob' })
			.then((res) => {
                let blob = new Blob([res.data], {type: "application/octet-stream"}),
                    fileURL = URL.createObjectURL(blob),
                    link = document.createElement("a");

                link.download = 'Открытая часть ЭЦП.cer';
                link.href = fileURL;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                this.$notify({ group: 'foo', duration: 5000, type: 'success', title: 'Успех', text: 'Файл успешно загружен!' })
			})
			.catch((error) => {
                console.log(error)
                this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Ошибка при выгрузке файла, обратитесь к Администратору!' })
            })
        },
		addRoleColumn() {
			this.columns.unshift({
				name: 'Роль',
				nameEng: 'roleName',
				sort: false,
				click: false
			});

			this.columns.forEach(x => x.width = '18%');
		},
		addRoleFilter() {
			let roleFilter = { name: 'Роль', value: null, id: 'roles', type: 'select', list: [] };

			this.filter.unshift(roleFilter);

			this.apiAll({ type: 'get', url: 'api/Roles/GetMinzdravNps', id: undefined })
				.then(res => {
					roleFilter.list = res.data;
				});
		},
        get(params) {
            this.isLoading = true;
            this.apiAll({type: 'post', url: 'api/SignUser/Filters', obj: params })
                .then((res) => {
                    this.list = res.data;
                    this.allStroke = res.headers['x-total-count'];
                    this.resultCount = +res.headers['x-total-count'] ? Math.ceil(+res.headers['x-total-count'] / this.params.offSetFetch.fetchRowCount) : 1;
                    this.isLoading = false;
                })
        }
	},
	created() {
		if (this.authUser.role.id === 10) {
			this.addRoleColumn();
			this.addRoleFilter();
		}

		this.get(this.params);

		// this.list = [
        //     {
        //         id: '57feb011-3132-434f-943e-b6ebc9095a1c',
        //         fio: 'Иванов Петр Алексеевич',
        //         login: 'IvanovPA@mail.ru',
        //         authorized: true,
		// 		eds: null
        //     },
		// 	{
        //         id: 'ee87f9c5-7d83-4fa5-9905-57c4b489dbdd',
        //         fio: 'Сидорова Анна Ивановна',
        //         login: 'sidorovaAI@mail.ru',
        //         authorized: false,
		// 		eds: 'test'
        //     },
		// 	{
        //         id: '168567ab-3856-4891-a3f1-05e0d3835238',
        //         fio: 'Петрова Ольга Сергеевна',
        //         login: 'petrovaOS@mail.ru',
        //         authorized: true,
		// 		eds: 'test'
        //     }
        // ];

		// this.allStroke = 3;
		// this.resultCount = 1;
	}
};
</script>

<style scoped>
@import '../../style/vuetify.min.css';
@import '../../style/stylePages.css';

.mb-2 {
    margin-bottom: 10px;
}

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-container {
	width: 700px;
	margin: 0px auto;
	/* padding: 20px 30px; */
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
	margin-top: 0;
	color: #42b983;
}
.modal-header {
	text-align: center;
	background: #003b84;
	color: white;
	font-size: 19px;
	border-bottom: 1px solid lightgray;
	padding: 10px;
}
.modal-footer {
	text-align: center;
	padding: 10px 20px;
}

.modal-body {
	margin: 20px 0;
	padding: 20px 100px;
}

.label {
	font-size: 18px;
}
.label-marg {
	margin-top: 15px;
}

.inp {
	padding: 10px;
	border-radius: 10px;
}
.inp:hover {
	background-color: #fafafa;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
	transition: 0.3s box-shadow;
}

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.pointer {
    cursor: pointer;
}

.custom-select {
    width: 45px;
    border-radius: 5px;
    text-align: center;
}

.ring-load {
	position: absolute;
	cursor: progress;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* opacity: 0.4; */
	z-index: 10;
	background-color: rgba(88, 88, 88, 0.2);
}

.ring-load-loading {
	left: 42%;
	top: 42%;
}

table {
	width: 100%;
}

.b-table-list__table td.plain-text {
	padding-top: 18px;
}

.b-table-list__table td.plain-text .hint {
	color: #9b9b9b;
}

.primary {
    background-color: #0061d9 !important;
    border-color: #0061d9 !important;
}

.primary--text {
    color: #0061d9 !important;
    caret-color: #0061d9 !important;
}

.btn-link {
    font-weight: 400;
    letter-spacing: normal;
    text-transform: none;
    text-decoration: underline;
}

.v-btn.btn-link:not(.v-btn--round).v-size--default {
	height: auto;
	padding: 0;
}

.v-btn.btn-link.v-size--default {
	font-size: 14px;
}

.v-btn .v-btn__content {
	padding-top: 1px;
}

.v-chip-group .v-label {
	line-height: 1.75rem;
}

.v-chip {
	border-color: rgba(0,0,0,.12);
    color: rgba(0,0,0,.87);
	overflow: visible;
}

.v-chip:not(.v-chip--active) {
    background: #e0e0e0;
}

.v-chip:not(.v-chip--outlined).primary {
	color: #fff;
}

.v-input--selection-controls__input {
    margin-right: 8px;
}

.v-input--switch .v-input--switch__track {
    color: rgba(0, 0, 0, 0.38);
}

.v-input--switch .v-input--switch__thumb {
    color: #FFFFFF;
}

.v-input--switch .v-label {
	font-size: 14px;
	color: #04092a;
}

.v-input--switch.v-input--dense .v-input--selection-controls__ripple {
    left: -12px;
}

.v-input--switch--inset.v-input--is-dirty .v-input--selection-controls__ripple,
.v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
    transform: translate(20px, 0) !important;
}

.v-input--is-disabled {
	opacity: 0.8;
}

.v-label--is-disabled {
	opacity: 0.6;
}
</style>
